.kijima-btn {
  background-color: var(--color-dark-blue);
  color: white;
  border: 1px solid white;
  font-size: 1.875rem;
  border-radius: 5rem;
  min-width: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 1rem 1.2rem;
  transition: all 0.3s ease;
  font-weight: bold;
  cursor: pointer;
  z-index: 1;
  box-shadow: 0 0 0 5px var(--color-dark-blue);
  --box-shadow-hover-val: 0px 0px 3px 5px;

  .check-icon {
    font-size: 2rem;
    animation: antCheckboxEffect 0.36s ease-in-out;
    animation-fill-mode: backwards;
  }
  &.red {
    box-shadow: 0 0 0 5px var(--color-red);
    background-color: var(--color-red);
    &:hover,
    :active,
    :focus {
      border: 1px solid var(--color-red);
      box-shadow: var(--box-shadow-hover-val) rgba(228, 65, 65, 0.4);
    }
  }
  &.green {
    box-shadow: 0 0 0 5px var(--color-green);
    background-color: var(--color-green);
    &:hover,
    :active,
    :focus {
      border: 1px solid var(--color-green);
      box-shadow: var(--box-shadow-hover-val) rgba(9, 122, 5, 0.4);
    }
  }
  &.salmon {
    box-shadow: 0 0 0 5px var(--color-salmon);
    background-color: var(--color-salmon);
    &:hover,
    :active,
    :focus {
      border: 1px solid var(--color-salmon);
      box-shadow: var(--box-shadow-hover-val) var(--color-salmon);
    }
  }
  &.yellow {
    box-shadow: 0 0 0 5px var(--color-yellow);
    background-color: var(--color-yellow);
    &:hover,
    :active,
    :focus {
      border: 1px solid var(--color-yellow);
      box-shadow: var(--box-shadow-hover-val) var(--color-yellow);
    }
  }
  &.black {
    background-color: #000000;
    box-shadow: 0 0 0 5px #000000;
    &:hover,
    :active,
    :focus {
      border: 1px solid #000000;
      box-shadow: var(--box-shadow-hover-val) rgba(0, 0, 0, 0.4);
    }
  }
  &.gray {
    background-color: gray;
  }
  &.orange {
    background-color: #e59605;
    box-shadow: 0 0 0 5px #e59605;
    &:hover,
    :active,
    :focus {
      border: 1px solid #e59605;
      box-shadow: var(--box-shadow-hover-val) #e59605;
    }
  }
  &.white {
    background-color: white;
    color: #0081f5;
    border: 1px solid #707070;
  }

  &:hover,
  :active,
  :focus {
    background-color: white;
    border: 1px solid var(--color-dark-blue);
    color: black;
    box-shadow: var(--box-shadow-hover-val) rgba(3, 74, 141, 0.4);
  }
}

.kijima-btn[disabled],
.kijima-btn[disabled]:hover,
.kijima-btn[disabled]:focus,
.kijima-btn[disabled]:active {
  background-color: #ccc;
  border: 1px solid #ccc !important;
  color: #fff;
  box-shadow: 0 0 1px 5px #ccc;
  // padding: 1rem 1.2rem;
}
