@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@700&display=swap');

body {
  margin: 0;
  font-family: 'Noto Serif JP', serif !important;
  color: #000000 !important;
  touch-action: pan-x, pan-y;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;  
} */

/* * {
  outline: solid 1px lightgray;
} */

@tailwind base;

@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  h4 {
    @apply text-sm;
  }
  h5 {
    @apply text-[1.3rem];
  }
  h6 {
    @apply text-[1.1rem];
  }
}
@tailwind components;
@tailwind utilities;
